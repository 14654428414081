import genericBlankImage from './1OP1.png';
import React from 'react';

const GenericImage = () => {
    return (
        <div className="container" style={{height: "140px"}}>
            <div style={{
                position: 'absolute',
                top: '20%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>No Image</div>
            <img style={{height: "140px", width:'100%'}} src={genericBlankImage}/>
        </div>
    )
}

export default GenericImage