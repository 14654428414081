import React, { useEffect, useState } from 'react';
import { IBoardgame } from './models/Game';
import { IUser } from './models/User';
import Collection from './Collection';
import Filter from './Filter';
import RandomGameDisplay from './RandomGameDisplay';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import LoadingButton from '@mui/lab/LoadingButton';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import background from './1OP1.png';
import Paper from '@mui/material/Paper';
import {getAllUsers, getAllGames, patchSaveRating} from './services/apiServices';
import { orderRecommendedPlayerNumbers } from './utils/OrderRecommendedPlayerNumbersUtil';

Amplify.configure(awsconfig);

const App = () => {
    const [ userArr, setUserArr ] = useState<IUser[]>([]);
    const [ completeCollection, setCompleteCollection ] = useState<IBoardgame[]>([]);
    const [ filteredCollection, setFilteredCollection] = useState<IBoardgame[]>([]);
    const [ randomisedSelection, setRandomisedSelection] = useState<IBoardgame[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ loadingButton, setLoadingButton ] = useState<boolean>(false);
    const [ open, setOpen ] = useState<boolean>(false);
    const [ modalCardGame, setModalCardGame ] = useState<IBoardgame>();
    const [ originalCardGame, setOriginalCardGame ] = useState<IBoardgame>();
    const [ gameRating, setGameRating ] = useState<string>("Decent/Unknown");
    const [ ratingSaveButtonDisabled, setRatingSaveButtonDisabled ] = useState<boolean>(true);
    const [ currentUser, setCurrentUser ] = useState<IUser>();
    const [ showRandomisedSelection, setShowRandomisedSelection ] = useState<boolean>(false);
    const [ initialRender, setInitialRender ] = useState<boolean>(true);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalCardGame(originalCardGame);
        setGameRating('Decent/Unknown');
        setRatingSaveButtonDisabled(true);
        setLoadingButton(false);
        setOpen(false);
    }
    const handleSaveAndClose = () => {
        setLoadingButton(false);
        setOriginalCardGame(modalCardGame);
        setGameRating('Decent/Unknown');
        setRatingSaveButtonDisabled(true);
        setOpen(false);
        getData();
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '55%',
        ['@media (min-width:750px)']: { // eslint-disable-line no-useless-computed-key
            width: '30%'
        },

        minHeight: '20%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        setCurrentUser(userArr.find((user) => user.id === Amplify.Auth.user.attributes.sub));
    }, [userArr])

    useEffect(() => {
        if(userArr.length > 0 && completeCollection.length > 0) {
            setLoading(false);
        }
    }, [userArr, completeCollection])
    
    useEffect(() => {
        const tempRandomGameArr: IBoardgame[] = [];
        if (filteredCollection.length > 0) {
            const tempFilteredCollection: IBoardgame[] = [...filteredCollection];
            let loopLimit =3;
            if (tempFilteredCollection.length < loopLimit) {
                for (let loop = 0; loop < tempFilteredCollection.length + 1; loop++) {
                    let num = Math.floor(Math.random() * ((tempFilteredCollection.length -1) + 1))
                    tempRandomGameArr.push(tempFilteredCollection[num]);
                    tempFilteredCollection.splice(num, 1);
                }
            } else {
                for (let loop = 0; loop < 3; loop++) {
                    let num = Math.floor(Math.random() * ((tempFilteredCollection.length -1) + 1))
                    tempRandomGameArr.push(tempFilteredCollection[num]);
                    tempFilteredCollection.splice(num, 1);
                }
            }
        }
        setRandomisedSelection([...tempRandomGameArr])
        if (!initialRender) {
            setShowRandomisedSelection(true);
        }
        setInitialRender(false);
        // eslint-disable-next-line
    }, [filteredCollection])

    const getData = async () => {
        setLoading(true);
        setUserArr([...(await getAllUsers())])
        setCompleteCollection([...(await getAllGames())])
    }

    const handleFilterSubmit =  (userFilter: any, playerCount: number, duration: number[], includeAllGames: boolean, filterByRecommendedPlayerNumber: boolean) => {
        const tempCollection: IBoardgame[] = [];
        completeCollection.forEach((game) => {
            const numOfPlayersRecArr: any = orderRecommendedPlayerNumbers(filterByRecommendedPlayerNumber ? game.bestNumOfPlayers : game.minPlayers, filterByRecommendedPlayerNumber ? game.recommendedNumOfPlayers : game.maxPlayers);
            if (includeAllGames) {
                if (duration[1] < 240) {
                    if ((playerCount >= numOfPlayersRecArr.lowest && playerCount <= numOfPlayersRecArr.highest) && (game!.averageDurationMins! >= duration[0] && game!.averageDurationMins! <= duration[1])) {
                        
                        let ownerCheck = false;
                        ownerCheck = game!.inUsersCollection!.some((owner) => userFilter.includes(owner));
                        
                        if (ownerCheck) {
                            tempCollection.push(game);
                        }
                    }
                } else {
                    if ((playerCount >= numOfPlayersRecArr.lowest && playerCount <= numOfPlayersRecArr.highest) && (game!.averageDurationMins! >= duration[0])) {
                        let ownerCheck = false;
                        ownerCheck = game!.inUsersCollection!.some((owner) => userFilter.includes(owner));
                        
                        if (ownerCheck) {
                            tempCollection.push(game);
                        }
                    }
                }
            } else {
                if (duration[1] < 240) {
                    if ((playerCount >= numOfPlayersRecArr.lowest && playerCount <= numOfPlayersRecArr.highest) && (game!.averageDurationMins! >= duration[0] && game!.averageDurationMins! <= duration[1]) && (game.rating !== "Wouldn't Play Again")) {
                        let ownerCheck = false;
                        ownerCheck = game!.inUsersCollection!.some((owner) => userFilter.includes(owner));
                        
                        if (ownerCheck) {
                            tempCollection.push(game);
                        }
                    }
                } else {
                    if ((playerCount >= numOfPlayersRecArr.lowest && playerCount <= numOfPlayersRecArr.highest) && (game!.averageDurationMins! >= duration[0]) && (game.rating !== "Wouldn't Play Again")) {
                        
                        let ownerCheck = false;
                        ownerCheck = game!.inUsersCollection!.some((owner) => userFilter.includes(owner));
                        
                        if (ownerCheck) {
                            
                            tempCollection.push(game);
                            
                        }
                    }
                }
            }
        })
        setFilteredCollection([...tempCollection]);
    }

    const handleClearFilter = () => {
        setRandomisedSelection([]);
        setShowRandomisedSelection(false);
    }

    const handleCardModal = (id: string) => {
        const game = completeCollection.find((game) => game.id === id);
        setModalCardGame(game);
        handleOpen();
    }

    const handleDropdownChange = (event: SelectChangeEvent) => {
        setGameRating(event.target.value);
        setOriginalCardGame(modalCardGame);
        const updatedGame: IBoardgame = {...modalCardGame!};
        updatedGame.rating = event.target.value;
        updatedGame.ratingSetBy = currentUser?.firstName;
        setModalCardGame(updatedGame);
        if (event.target.value !== '') {
            setRatingSaveButtonDisabled(false);
        }
    }

    const handleSaveRating = async () => {
        setLoadingButton(true);
        setRatingSaveButtonDisabled(true);
        if(modalCardGame) {
            await patchSaveRating(modalCardGame);
            handleSaveAndClose();
        }
    }

    return <>
        <div>
            <div style={{backgroundImage: `url(${background})`}}>
                <div style={{margin: 'auto', width: '80%', paddingTop: '5px'}}>
                    <Paper>
                        <Typography variant="h3" component="div" color="common.black" style={{display: 'inline-block', paddingLeft: '5px', paddingTop: '10px', paddingBottom: '10px'}}>
                            Hoardr
                        </Typography>
                        {!loading &&
                            <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', float: 'right', marginBottom: '20px'} }>
                                <AmplifySignOut style={{display: 'inline-block', paddingTop:'15px', marginRight: '5px'}}/>
                            </div>
                        }
                    </Paper>
                </div>
            
                <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
                    <Filter 
                        users={userArr} 
                        handleFilterSubmit={handleFilterSubmit}
                        handleClearFilter={handleClearFilter}
                    />
                </div>
            </div>
            <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
                {!showRandomisedSelection &&
                    <Collection 
                        users={userArr} 
                        games={completeCollection}
                        loading={loading}
                        handleCardModal={handleCardModal}
                    />
                }
                {showRandomisedSelection &&
                    <RandomGameDisplay 
                        userArr={userArr} 
                        randomisedSelection={randomisedSelection}
                        handleClearFilter={handleClearFilter}
                        handleCardModal={handleCardModal}
                    />
                }
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography component="div" variant="h5">
                                    {modalCardGame?.title}
                                </Typography>
                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <img 
                                            style={{
                                                marginTop: '20px',
                                                display: 'block',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                width: '70%'
                                            }} 
                                            src={modalCardGame?.image}
                                            alt={'boardgame cover'}
                                        />
                                    </Grid>
                                    <FormControl style={{width: '100%'}}>
                                        <InputLabel id="demo-simple-select-label" style={{marginTop: '40px'}}>Rating</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={modalCardGame?.rating ?? gameRating}
                                            input={<OutlinedInput label="Name" />}
                                            label="Rating"
                                            onChange={handleDropdownChange}
                                            style={{marginTop: '40px'}}
                                        >
                                            <MenuItem value={"Wouldn't Play Again"}>Wouldn't Play Again</MenuItem>
                                            <MenuItem value={"Decent/Unknown"}>Decent/Unknown</MenuItem>
                                            <MenuItem value={"Addicted, Again, Again!"}>Addicted, Again, Again!</MenuItem>
                                        </Select>
                                        {modalCardGame?.rating === "Wouldn't Play Again" &&
                                            <Typography component="div" variant="caption" style={{margin: 'auto', marginTop: '10px'}}>
                                                Set By {modalCardGame?.ratingSetBy}. Don't agree? Fight me!
                                            </Typography>
                                        }
                                        <div style={{display: 'inline-block', float: 'right'}} >
                                            <LoadingButton 
                                                variant="contained" 
                                                color="primary" 
                                                type="button" 
                                                style={{marginTop: '40px', width: '30%', float: 'right'}} 
                                                disabled={ratingSaveButtonDisabled}
                                                loading={loadingButton}
                                                onClick={handleSaveRating}
                                            >
                                                Save
                                            </LoadingButton>
                                        </div>
                                    </FormControl>
                                
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </div>
    </>
}

export default withAuthenticator(App);
  