import { IBoardgame } from '../models/Game';
import { GlobalVars } from '../staticVars';

const baseUrl: string = GlobalVars.BASE_URL;

export const getAllUsers = () => {
    return fetch(baseUrl + 'users/')
    .then(response => response.json())
    .then(data => data);
}

export const getAllGames = () => {
    return fetch(baseUrl + 'games/')
        .then(response => response.json())
        .then(data => data);
}

export const patchSaveRating = (modalCardGame: IBoardgame) => {
    return fetch(baseUrl + 'games/' + modalCardGame?.id, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(modalCardGame),
    })
    .then(response => response.json())
}
