export const generateRecommendedPlayerNumberString = (best: any, recNum: any) => {
    let lowest = best > recNum ? recNum : best;
    let highest = best > recNum ? best : recNum;
    const recommendedString = lowest === highest ? lowest : `${lowest} - ${highest}`
    return recommendedString;
}

export const orderRecommendedPlayerNumbers = (best: any, recNum: any) => {
    let lowest = best > recNum ? recNum : best;
    let highest = best > recNum ? best : recNum;
    return {
        lowest, 
        highest
    };
}