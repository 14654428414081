import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { generateRecommendedPlayerNumberString } from './utils/OrderRecommendedPlayerNumbersUtil';
import genericImage from "./1OP1.png";
import GenericImage from "./genericImage";

const GameCard = (props: any) => {

    const onCardClick = (id: string) => {
        props.handleCardModal(id);
    }

    return (
        <Card sx={{ width: '100%', display: 'inline-block'}} onClick={() => onCardClick(props.game.id)}>
            <CardActionArea>
            {props.game.image ? (<CardMedia
                    component="img"
                    height="140"
                    image={props.game.image}
                    alt="image of board game"
                />
            ) : <GenericImage /> }
                <CardContent>
                    <Tooltip title={props.game.title}>
                        <Typography gutterBottom variant="h5" component="div" noWrap>
                            {props.game.title}
                        </Typography>
                    </Tooltip> 
                    <Typography variant="body1" color="text.secondary">
                        Average Duration: {props.game.averageDurationMins} mins
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Players: {generateRecommendedPlayerNumberString(props.game.minPlayers, props.game.maxPlayers)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Recommended: {generateRecommendedPlayerNumberString(props.game.bestNumOfPlayers, props.game.recommendedNumOfPlayers)}
                    </Typography>
                    <Typography component={'span'} variant="body2" color="text.secondary" style={{display:'inline-block'}}>
                        {`Owners: `} 
                        <Stack direction="row" spacing={2}>
                            {props.game.inUsersCollection?.map((user: any) => {
                                const userIndex = props.userArr.findIndex((person: any) => {
                                    return person.username === user
                                }); 
                                return(
                                    <Tooltip title={props.userArr[userIndex]!.firstName!} key={user}> 
                                        <Avatar sx={{ 
                                                width: 35, 
                                                height: 35, 
                                                bgcolor: props.userArr[userIndex].avatarColour
                                            }}>
                                                {props.userArr[userIndex]!.firstName![0].toUpperCase()}
                                            </Avatar>
                                    </Tooltip>  
                                )
                            })}
                        </Stack>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card> 
    )
}

export default GameCard
