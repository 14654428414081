import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { IUser } from './models/User';

const Filter = (props: any) => {

    const [ userArr, setUserArr ] = useState<IUser[]>([]);
    const [ userFilter, setUserFilter ] = useState<string[]>([])
    const [ playerCount, setPlayerCount ] = useState<number>(4);
    const [ duration, setDuration ] = useState<any>([60, 120]);
    const [ includeAllGames, setIncludeAllGames ] = useState<boolean>(false);
    const [ filterByRecommendedPlayerNumber, setFilterByRecommendedPlayerNumber ] = useState<boolean>(true);
    const [ loadingUsers, setLoadingUsers ] = useState<boolean>(true);

    useEffect(() => {
        setUserArr(props.users)
        if (props.users.length > 0) {
            setLoadingUsers(false)
        }
    }, [props])

    const updateUserFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempUserArr = userFilter;
        if (event.target.checked) {
            tempUserArr.push(event.target.name);
        } else {
            tempUserArr.splice(tempUserArr.findIndex((user) => user === event.target.name), 1);
        }
        setUserFilter([...tempUserArr]);
    }

    const updatePlayerCount = (event: Event, newValue: number | number[]) => {
        setPlayerCount(newValue as number)
    }

    const updateDuration = (event: Event, newValue: number | number[]) => {
        setDuration(newValue as number[])
    }

    const handleFilterSubmit =  (e: any) => {
        e.preventDefault();
        props.handleFilterSubmit(userFilter, playerCount, duration, includeAllGames, filterByRecommendedPlayerNumber);
    }

    const includeBadlyRankGames = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeAllGames(event.target.checked)
    }

    const filterByMinMaxPlayers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterByRecommendedPlayerNumber(event.target.checked)
    }

    const valueLabelFormat = (value: number) => {
        let labelValue: string = value.toString(); 
        if(value === 240) {
            labelValue = `${labelValue}+`;
        } 
        return labelValue
    }

    return (
        <div style={{margin: 'auto', width: '80%', padding: '2% 0 2% 0'}}>
            <Paper elevation={3} style={{ backgroundColor: '#f5f5f5'}}>
                
                    <div style={{width: '95%', margin: 'auto'}}>
                        <div style={{width: '95%', margin: 'auto'}}>
                            <Typography variant="h5" component="div" style={{marginTop: '10px', marginBottom: '10px', color: 'black'}}>
                                Randomiser:
                            </Typography>
                        </div>
                   </div>
            
                    <div style={{width: '95%', margin: 'auto'}}>
                        <form onSubmit={handleFilterSubmit}>
                            <Box sx={{ width: '100%' }}> 
                            <Paper style={{marginBottom: '40px'}}>
                                <div style={{width: '95%', margin: 'auto'}}>
                                    {loadingUsers && 
                                        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1%'}}>
                                            <CircularProgress size="2rem" color="inherit"/>
                                        </div>
                                    }
                                    <Grid
                                        container
                                        columns={10}
                                        style={{margin: 'auto', width: '80%'}}
                                    >
                                        {userArr.map((user) => {
                                            return (
                                                <Grid item key={user.username} style={{display: 'inline-block', margin: 'auto'}}>
                                                <Tooltip 
                                                    title={user!.firstName!} 
                                                > 
                                                    <Checkbox
                                                        onChange={updateUserFilter}
                                                        name={user.username}
                                                        icon={
                                                            <Avatar sx={{width: 25, height: 25, bgcolor: '#d3d3d3', fontSize: '13px'}}> 
                                                                {user.initials}
                                                            </Avatar>
                                                        }
                                                        checkedIcon={
                                                            <Avatar sx={{width: 25, height: 25, bgcolor: '#1a76d2', fontSize: '16px'}}> 
                                                                {user.initials}
                                                            </Avatar>
                                                        }
                                                    />
                                                </Tooltip>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Typography variant="body2" gutterBottom style={{textAlign: 'center'}}>Collections</Typography>
                                    
                                </div>
                                </Paper>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Paper style={{marginBottom: '40px'}}>
                                <div style={{width: '95%', margin: 'auto', paddingBottom: '10px'}}>
                                
                                    <Slider
                                        style={{width: '100%'}}
                                        aria-labelledby="player-slider"
                                        value={playerCount}
                                        valueLabelDisplay="on"
                                        onChange={updatePlayerCount}
                                        step={1}
                                        marks
                                        min={1}
                                        max={12} 
                                    />
                                    <Typography variant="body2" gutterBottom style={{textAlign: 'center'}}>Players</Typography>
                                </div>
                                </Paper>
                                <Paper>
                                <div style={{width: '95%', margin: 'auto', paddingBottom: '10px'}}>
                                    
                                    <Slider
                                        style={{width: '100%'}}
                                        aria-labelledby="time-slider"
                                        value={duration}
                                        valueLabelDisplay="on"
                                        disableSwap
                                        onChange={updateDuration}
                                        valueLabelFormat={valueLabelFormat}
                                        step={10}
                                        marks
                                        min={10}
                                        max={240}
                                    />
                                    <Typography variant="body2" style={{textAlign: 'center'}}>Duration (mins)</Typography>
                                    
                                </div>
                                </Paper>
                            </Box>
                            <div style={{width: '95%', margin: 'auto', marginTop: '19px', paddingBottom: '5px', overflow:'hidden'}}>
                                <Tooltip 
                                    title='Include all poorly rated games in randomiser result'
                                >
                                    <FormControlLabel
                                        value={includeAllGames}
                                        control={<Checkbox onChange={includeBadlyRankGames}/>}
                                        label="All Games"
                                        style={{paddingBottom: '10px'}}
                                    />
                                </Tooltip>
                                <Tooltip 
                                    title='Filter by recommended number of or game min/max players'
                                >
                                <FormControlLabel
                                    value={filterByRecommendedPlayerNumber}
                                    control={<Checkbox defaultChecked onChange={filterByMinMaxPlayers}/>}
                                    label="By Rec Players"
                                    style={{paddingBottom: '10px'}}
                                />
                                </Tooltip>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit" 
                                    style={{float: 'right'}} 
                                    disabled={userFilter.length === 0}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                
            </Paper>
        </div>
    )
}

export default Filter;
