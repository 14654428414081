import React, { useEffect, useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { IUser } from './models/User';
import { IBoardgame } from './models/Game';
import GameCard from './GameCard';

const Collection = (props: any) => {
    const gridSection = useRef<null | HTMLDivElement>(null);
    const [ userArr, setUserArr ] = useState<IUser[]>([]);
    const [ completeCollection, setCompleteCollection ] = useState<IBoardgame[]>([]);
    const [ offset, setOffset ] = useState<number>(0);
    const [ collectionSize, setCollectionSize ] = useState<number>(0);
    const [ page, setPage ] = useState<number>(1);
    const [ pageItems, setPageItems] = useState<IBoardgame[]>([]);
    const [ searchInput, setSearchInput ] = useState<string>('');
    const pageSize: number = 20;

    useEffect(() => {
        if (props.users) {
            setUserArr(props.users);
        }
        if (props.games) {
            setCompleteCollection(props.games);
            setCollectionSize(props.games.length);
        }
    }, [props])

    useEffect(() => {
        setCurrentPageItems();
        // eslint-disable-next-line
    },[offset, completeCollection])

    const setCurrentPageItems = () => {
        const CurrentPageItems: IBoardgame[] = [];
        for (let loop = offset; loop < pageSize + offset; loop++) {
            if(completeCollection[loop]){
            CurrentPageItems.push(completeCollection[loop])
            } else {
                break;
            }
        }
        setPageItems(CurrentPageItems);
    }

    const onPagination = (e: any, page:any) => {
        setPage(page);
        setOffset((page - 1) * pageSize)
        // window.scrollTo(0, 0)
        if (gridSection.current !== null) {
            gridSection.current.scrollIntoView()
        }
    }

    useEffect(() => {
        if (searchInput === '') {
            setCurrentPageItems();
        } else {
            const commitSearch = setTimeout(() => {
                const searchResult = completeCollection.filter((game: IBoardgame) => {
                    let title = game.title.toLowerCase();
                    return title.match(searchInput.toLowerCase())
                })
                setPageItems(searchResult)
            }, 1000)
            return () => clearTimeout(commitSearch)
        }
        // eslint-disable-next-line
    }, [searchInput])

    return(
        <div style={{margin: 'auto', width: '80%'}}>
            <Box sx={{ flexGrow: 1 }} style={{paddingBottom: '50px'}}>
                <div ref={gridSection} style={{padding: '5px'}}>
                    <Typography gutterBottom variant="h5" component="div" style={{marginBottom: '20px', display: 'inline-block', marginTop: '11px'}}>
                        Games: {collectionSize}
                    </Typography>
                    {!props.loading &&
                        <TextField id="gameSearch" label="Search Title..." variant="outlined" style={{float: 'right'}} onChange={(event) => setSearchInput(event.target.value)}/>
                    }
                </div>
                {props.loading &&
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '25%'}}>
                        <CircularProgress size="5rem" color="inherit"/>
                    </div>
                }
            
                {!props.loading &&
                    <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 2 }} columns={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                        {pageItems?.map((game) => {
                            return (
                                <Grid item xs={2} sm={2} md={2} lg={2} key={game.id}>
                                    <GameCard
                                        key={game.id}
                                        game = {game}
                                        userArr = {userArr}
                                        handleCardModal = {props.handleCardModal}
                                    /> 
                                </Grid>
                            )
                        })}
                    </Grid>
                }
                {pageItems.length === 0 &&
                    <Typography gutterBottom variant="h6" component="div" style={{textAlign:'center', marginTop: '40px', color: '#ff9900'}}>
                        No games found with that title.
                    </Typography>
                }
                {!props.loading && searchInput === '' &&
                    <Pagination 
                        count={Math.ceil(collectionSize! / pageSize)} 
                        variant="outlined" 
                        shape="rounded" 
                        onChange={onPagination}
                        siblingCount={1}
                        boundaryCount={1}
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={true}
                        hidePrevButton={true}
                        page={page}
                        size='small'
                        style={{justifyContent:"center", marginTop: '20px', marginBottom: '20px', display:'flex'}}
                    />    
                }
            </Box>
        </div>
    )
}

export default Collection;
