import React, { useEffect, useState } from "react";
import { IBoardgame } from './models/Game';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GameCard from "./GameCard";

const RandomGameDisplay = (props: any) => {

    const [ randomisedSelection, setRandomisedSelection ] = useState<IBoardgame[]>([]);

    useEffect(() => {
        setRandomisedSelection([...props.randomisedSelection]);
    }, [props.randomisedSelection])

    return <>
        <div style={{margin: 'auto', width: '80%'}}>
            <Box sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div" style={{display: "inline-block", marginBottom: '20px'}}>
                    Narrowed Selection
                </Typography>
                <Button variant="contained" color="secondary" type="button" style={{display: "inline-block", float: 'right', width: '30%', maxWidth: '150px'}} onClick={props.handleClearFilter}>
                    Back
                </Button>
            </Box>
            {randomisedSelection.length === 0 &&
                <Typography gutterBottom variant="h6" component="div" style={{textAlign:'center', marginTop: '40px', color: '#ff9900'}}>
                    No games found with those settings.
                </Typography>
            }
            <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 2 }} columns={{ xs: 2, sm: 4, md: 6, lg: 9 }}>
                {randomisedSelection?.map((game) => {
                    return (
                        <Grid item xs={2} sm={2} md={2} lg={3} key={game.id}>
                            <GameCard
                                key={game.id}
                                game = {game}
                                userArr = {props.userArr}
                                handleCardModal = {props.handleCardModal}
                            /> 
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    </>
}

export default RandomGameDisplay;